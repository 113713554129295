/*******************************
     User Global Variables
*******************************/
.daycy.calendar > .grid > .row > .cell.range {
  background-color: #79f7a1;
}
.daycy.calendar > .grid > .row > .cell.week {
  color: #0ccb48;
}
.daycy.calendar > .grid > .row > .cell.week.label {
  color: rgba(34, 36, 38, 0.5);
}
.daycy.calendar > .grid > .row > .cell.selected {
  background-color: #11933A;
}
