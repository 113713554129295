@font-face {
    font-family: "cy-custom-icons";
    src: url("./cy-custom-icons.eot?be8a92aadd27707753deb869b163ab4c?#iefix") format("embedded-opentype"),
url("./cy-custom-icons.woff2?be8a92aadd27707753deb869b163ab4c") format("woff2"),
url("./cy-custom-icons.woff?be8a92aadd27707753deb869b163ab4c") format("woff"),
url("./cy-custom-icons.ttf?be8a92aadd27707753deb869b163ab4c") format("truetype"),
url("./cy-custom-icons.svg?be8a92aadd27707753deb869b163ab4c#cy-custom-icons") format("svg");
}

i.icon.cy {
    font-family: cy-custom-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

i.icon.cy:before {
    content: "\f101";
}
