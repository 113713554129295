.nav-item {
    font-size: 16px;
}

html {
    font-size: 14px;
}

body {
    font-family: 'Ubuntu', 'Lato', sans-serif !important;
}

.ui, .ui.header, .ui.button {
    font-family: 'Ubuntu', 'Lato', sans-serif !important;
}

h1, h2, h3, h4, h5,
.ui.header,
.ui.button,
.ui.form .field > label,
.ui.table thead th {
    font-weight: 500 !important;
}

/* Make none fullscreen modal have the close icon inside the modal instead of
 * outside.
 */
.ui.modal > .close {
    top: 1.0535rem;
    right: 1rem;
    color: rgba(0, 0, 0, 0.87);
}

a:hover {
    color: unset;
    text-decoration: unset;
}

.ui.disabled.input > input:disabled {
    opacity: 1 !important; /* this will get the 0.45 opacity twice otherwise and thus become unreadable */
}
